import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import imgHero from "../assets/image/conference/png/hero-main.png";
import imgC1 from "../assets/image/conference/jpg/content-1-img.jpg";
import imgCR from "../assets/image/conference/png/content-1-round.png";
import Content4 from "../sections/landing6/Content3";

const Hero = () => {
  return (
    <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
      {/* <!-- Hero Area --> */}
      <div className="bg-default-6 mt-28 position-relative">
        <div
          className="hero-area bg-image bg-blue  dark-mode-texts mx-6 mx-lg-9 rounded-15"
          css={`
            background-image: url(https://shadepro-gatsby.netlify.app/static/pattern-4-8263160699f4d9a09419f6536a57e66a.png);
          `}
        >
          <Container>
            <Row className="justify-content-sm-end">
              <Col lg="6" md="8">
                <div className="hero-content pe-xl-16 pt-18 pt-lg-31 pb-md-18 pb-lg-30 mb-9 mb-md-0">
                  <h1 className="title gr-text-2 mb-8 heading-color">Training & Consulting</h1>
                  <p className="gr-text-8 mb-0 text-color-opacity">
                  We ensure expertise in emerging technologies, fostering continuous learning and innovation for superior service delivery.
                  </p>
                  <Button href="/contact" variant="success" className=" mt-11 btn btn-success">
                    Contact us
                  </Button>
                </div>
              </Col>
              <Col
                lg="5"
                md="4"
                sm="7"
                className="offset-lg-1 align-self-sm-end position-relative">
                <div
                  className="hero-img hero-img-custom z-index-1 mb-n12 mb-md-0"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="500"
              >
                </div>
              </Col>
            </Row>
          </Container>
        </div>
          </div>
        <div className="content-section pt-13 pt-lg-29 pb-12 pb-lg-20 bg-default-6">
        <Container>
          <Row className="align-items-center ">
            <Col className="ps-lg-15">
              <div className="section-title content-text mb-13 mb-lg-15">
                <h2 className="title gr-text-4 mb-6 heading-color">Our Core Offerings</h2>
                <p className="gr-text-8 pe-lg-12 mb-0 text-color-opacity">
                We deliver cutting-edge expertise in technology adoption, digital transformation strategy, and talent development, equipping organizations to navigate complex IT landscapes with confidence.
                </p>
              </div>
              <div className="content-widget">             
                <Row className="mb-n11">
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="750"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        1. Training Programme
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      AIN can help you acquire new skills or sharpen your existing expertise. We can help you achieve your education goals for IBM & Oracle software solutions. A broad spectrum of training courses can be delivered by our in house staff or by our training partners.
                         </p>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        2. Knowledge Transfer
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      We also deliver 1 on 1 Trainings & Knowledge Transfer for critical assignments to all our Individual & Corporate Clientele. A broad spectrum of training courses can be delivered by our in house staff or by our training partners.
                      </p>
                    </div>
                </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        3. Training Programme Reselling
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      We sell and resell a lot of popular courses, so we can - Help you find and register for courses at a discount Develop and deliver a customized courses based on audience Delivery mechanism can be Virtual, onsite or near your company site Sign Up Now Process AIN has well-laid out all the process relevant to Staffing, Services, Sales and Training.
                      </p>
                    </div>
                  </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        4. Enablement Process
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      We will work with you to get all your current vendors enrolled. You will want to continue enrolling any new vendors. You will also want to follow up with any current vendors who do not get enrolled during the initial period.
                      </p>
                    </div>
                  </Col>
                 <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        5. Project Management & Delivery Process
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Organizations continue to be challenged with the consistent application of defined project management processes, resource planning, forecasting, and other business demands.
                      </p>
                    </div>
                  </Col>               
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
    </div>
    <Content4 />
    </PageWrapper>
  );
};

export default Hero;